import Apiservice from '@/common/api.service';
import {API_URL} from '@/common/config.js'

const apiService = Apiservice;

export default class AmebAwardExamService
{
    #api = null;
    
    constructor() {
        this.#api = API_URL + 'admin/ameb-award-exam';
    }

    getPendingCertificates(data={},index = null) {
        let url = `${this.#api}/pending/certificates`;
        if (index != null)
            url = `${url}?page=${index}`;
        let param ={
            params: data
        }
        return apiService.query(url,param);

    }

    getPendingLabels(data={},index = null) {
        let url = `${this.#api}/pending/labels`;
        if (index != null)
            url = `${url}?page=${index}`;
        let param ={
            params: data
        }
        return apiService.query(url,param);

    }
}
